<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
      <div class="col-sm-12">
          <div class="card">
              <div class="card-body">
                  <p>Cargar contenido del tema</p>
                  <hr>
                  <div class="row product-adding">
                    <div class="col-xl-6">
                        <div class="add-product">
                            <div class="row">
                                <div class="col-xl-12 col-sm-12 col-xs-12">
                                    <div *ngIf="previewVideo" class="box-input-file">
                                        <img class="imgUpload" alt="" [src]="previewVideo">
                                    </div>
                                    <img *ngIf="viewLoaderVideo" src="./assets/images/loading-9.gif" alt="" style="width: 30%;">
                                    <input type="file" class="file-input" [accept]="requiredFileType" (change)="onVideoSelected($event)" #fileUploadVideo>
                                    <div class="file-upload">
                                    {{fileNameVideo || "Seleccionar video...  "}}
                                        <button class="btn btn-primary mat-btn" (click)="fileUploadVideo.click()">
                                            <i class="fa fa-video-camera" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="add-product">
                            <div class="row">
                                <div class="col-xl-12 col-sm-12 col-xs-12">
                                    <div *ngIf="previewFile" class="box-input-file">
                                        <img class="imgUpload" alt="" [src]="previewFile">
                                    </div>
                                    <div *ngIf="viewLoaderFile" class="spinner-border text-danger" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <input type="file" class="file-input" [accept]="requiredFileType" (change)="onFileSelected($event)" #fileUpload>
                                    <div class="file-upload">
                                    {{fileName || "Seleccionar archivo...  "}}
                                        <button class="btn btn-primary mat-btn" (click)="fileUpload.click()">
                                            <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-body">
                <p>Contenido cargado del tema</p>
                <hr>
                <div class="row product-adding">
                  <div class="col-xl-6">
                      <div *ngIf="content?.VideoUrl.Url">
                        <a [href]="content?.VideoUrl.Url" target="_blank"><img class="resize" src="./assets/images/reproductor-de-video.png" alt=""> <span class="titlefiles">{{content?.VideoUrl.OriginalName}}</span></a>
                      </div>
                  </div>
                  <div class="col-xl-6">
                        <div *ngFor="let document of content?.Documents">
                            <a [href]="document.Url" target="_blank"><img class="resize" src="./assets/images/pdf.png" alt=""> <span class="titlefiles">{{document.OriginalName}}</span></a>
                        </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</div>


<ng2-toasty></ng2-toasty>
