import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserModel } from '../../auth/auth.models';
import { getHeaders } from '../../utils/headers.functions';

const BASE_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  
  constructor(
    private http: HttpClient
  ) { }

  public createAdmin(user: UserModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/users`, user, { headers });
  }

  public createTeacher(user: UserModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/teachers`, user, { headers });
  }

  public getAdmins(limit:number, offset:number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/users?limit=${limit}&offset=${offset}`, { headers });
  }

  public getAdminsFiltered(value: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/users?limit=500&offset=0&q=${value}`, { headers });
  }

  public getTeachers(limit:number, offset:number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/teachers?limit=${limit}&offset=${offset}`, { headers });
  }

  getTeachersFiltered(value: string) {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/teachers?limit=500&offset=0&q=${value}`, { headers });
  }

  public getBusiness(limit:number, offset:number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/business?limit=${limit}&offset=${offset}`, { headers });
  }

  getBusinessFiltered( value: string) {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/business?limit=500&offset=0&q=${value}`, { headers });
  }
  

  public getUsers(limit:number, offset:number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/students?limit=${limit}&offset=${offset}`, { headers });
  }

  getUsersFiltered(limit: number, offset: number, value: string) {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/students?limit=${limit}&offset=${offset}&q=${value}`, { headers });
  }

  public getStudent(thisUserId: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/student/${thisUserId}`);
  }

  public getUser(thisUserId: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/user/${thisUserId}`);
  }

  public deleteUser(id: string): Observable<any> {
    return this.http.delete<any>(`${BASE_URL}/user/${id}`);
  }

  public deleteTeacher(id: string): Observable<any> {
    return this.http.delete<any>(`${BASE_URL}/teacher/${id}`);
  }

  public updateUser(id: number, user: any): Observable<any> {
    return this.http.put<any>(`${BASE_URL}/user/${id}`, user);
  }

  public updateTeacher(id: number, user: any): Observable<any> {
    return this.http.put<any>(`${BASE_URL}/teacher/${id}`, user);
  }

  public mapRequiredValues(user: any): any {
    const userInfo: any = {};

    userInfo.firstName = user.firstName;
    userInfo.lastName = user.lastName;
    userInfo.city = user.city;
    userInfo.country = user.country;
    userInfo.birthday = user.birthday;
    userInfo.email = user.email;
    userInfo.phoneNumber = user.phoneNumber;
    userInfo.roles = user.roles;

    return userInfo;
  }
}
