<div class="container-fluid">
    <div class="row mb-5">
        <div class="col-xl-4 col-md-6 col-xs-12">
            <div class="card o-hidden  widget-cards">
                <div class="bg-secondary card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'user'" class="font-secondary"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">Miembros</span>
                            <h3 class="mb-0"><span class="counter" [CountTo]="admin?.userstotal[2]?.count" [from]="0"
                                    [duration]="2">{{admin?.userstotal[2]?.count}}</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-md-6 col-xs-12">
            <div class="card o-hidden widget-cards">
                <div class="bg-primary card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'user-plus'" class="font-primary"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">Empresas</span>
                            <h3 class="mb-0"><span class="counter" [CountTo]="admin?.userstotal[1].count" [from]="0"
                                    [duration]="2">{{admin?.userstotal[1].count}}</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-md-6 col-xs-12">
            <div class="card o-hidden widget-cards">
                <div class="bg-danger card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'clipboard'" class="font-danger"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">Cursos vendidos</span>
                            <h3 class="mb-0"><span class="counter" [CountTo]="admin?.totalsold" [from]="0"
                                    [duration]="2">{{admin?.totalsold}}</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-md-6 col-xs-12">
            <div class="card o-hidden widget-cards">
                <div class="bg-warning card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-3">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'users'" class="font-warning"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-9"><span class="m-0">Usuarios registrados</span>
                            <h3 class="mb-0"><span class="counter" [CountTo]="admin?.totalusers" [from]="0"
                                    [duration]="2">{{admin?.totalusers}}</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-md-6 col-xs-12">
            <div class="card o-hidden widget-cards">
                <div class="bg-warning card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-3">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'dollar-sign'" class="font-warning"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-9"><span class="m-0">Ingresos</span>
                            <h3 class="mb-0"><span class="counter">{{admin?.totalmoney | currency}}</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <h3>{{month + ' ' + year}}</h3>
    <hr>
    <div class="row">
        <div class="col-xl-3 col-md-6 col-xs-12">
            <div class="card order-graph sales-carousel">
                <div class="card-header">
                    <h6>Nuevos miembros</h6>
                    <div class="row">
                        <div class="col-xs-12 col-sm-8">
                            <div class="value-graph">
                                <h3>{{dashdates?.usersstudents}}</h3>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-4">
                            <div class="media">
                                <div class="bg-secondary b-r-8">
                                    <div class="small-box">
                                        <i data-feather="user"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 col-xs-12">
            <div class="card order-graph sales-carousel">
                <div class="card-header">
                    <h6>Nuevas empresas</h6>
                    <div class="row">
                        <div class="col-xs-12 col-sm-8">
                            <div class="value-graph">
                                <h3>{{dashdates?.usersbusiness}}</h3>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-4">
                            <div class="media">
                                <div class="bg-primary b-r-8">
                                    <div class="small-box">
                                        <i data-feather="user-plus"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 col-xs-12">
            <div class="card order-graph sales-carousel">
                <div class="card-header">
                    <h6>Categorías Vendidas</h6>
                    <div class="row">
                        <div class="col-xs-12 col-sm-8">
                            <div class="value-graph">
                                <h3>{{dashdates?.items.length}}</h3>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-4">
                            <div class="media">
                                <div class="bg-secondary b-r-8">
                                    <div class="small-box">
                                        <i data-feather="shopping-cart"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 col-xs-12">
            <div class="card order-graph sales-carousel">
                <div class="card-header">
                    <h6>Ingresos</h6>
                    <div class="row">
                        <div class="col-xs-12 col-sm-8">
                            <div class="value-graph">
                                <h3>{{dashdates?.Familiestotalmoney | currency}}</h3>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-4">
                            <div class="media">
                                <div class="bg-warning b-r-8">
                                    <div class="small-box">
                                        <i data-feather="calendar"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-xl-12 xl-100 col-xs-12">
            <div class="card">
                <div class="card-header">
                    <h5>Top 5 Cursos</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">Curso</th>
                                    <th scope="col">Cantidad</th>
                                    <th scope="col">Monto</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let course of courses">
                                    <td class="font-primary">{{course.name}}</td>
                                    <td class="font-primary">{{course.value}}</td>
                                    <td class="digits">{{course.total | currency}}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12 xl-100 col-xs-12">
            <div class="card">
                <div class="card-header">
                    <h5>Top 5 Miembros</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Cantidad</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let member of members">
                                    <td class="font-primary">{{member.Name}}</td>
                                    <td class="font-primary">{{member.Quantity}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Ventas</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-6 col-sm-6" style="margin: auto;">
                            <div class="order-graph sm-order-space">
                                <div class="peity-chart-dashboard text-center chart-vertical-center">
                                    <ngx-charts-pie-chart class="pie-colours-1" [view]="view"
                                        [scheme]="doughnutChartColorScheme" [results]="graph"
                                        [explodeSlices]="false" [labels]="doughnutChartShowLabels" [arcWidth]=0.99
                                        [doughnut]="true" [animations]="false" [tooltipDisabled]="doughnutChartTooltip"
                                        [gradient]="doughnutChartGradient">
                                    </ngx-charts-pie-chart>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-sm-6">
                            <div class="order-graph sm-order-space">
                                <div class="order-graph-bottom sales-location" *ngFor="let item of graph; let i = index;">
                                    <div class="media">
                                        <div [ngClass]="{'order-shape-primary': i === 0, 'order-shape-second': i === 1, 'order-shape-third': i === 2, 'order-shape-four': i === 3, 'order-shape-five': i === 4, 'order-shape-six': i === 5, 'order-shape-seven': i === 6, 'order-shape-eight': i === 7, 'order-shape-nine': i === 8, 'order-shape-ten': i === 9}"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 mr-0" style="font-size: 12px;">{{item.name}} <span class="pull-right">{{item.value | number: '1.2-2'}}%</span></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
