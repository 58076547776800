import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CKEditorModule } from 'ngx-ckeditor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { WikiRoutingModule } from './wiki-routing.module';

import { ToastyModule } from 'ng2-toasty';
import 'hammerjs';
import 'mousetrap';

import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablesModule } from 'angular-datatables';
import { WikiComponent } from './wiki-list/wiki-list.component';
import { AddWikiComponent } from './add-wiki/add-wiki.component';
import { WikiDetailComponent } from './wiki-detail/wiki-detail.component';
import { AngularEditorModule } from '@kolkov/angular-editor';


@NgModule({
  declarations: [
    WikiComponent,
    AddWikiComponent,
    WikiDetailComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    WikiRoutingModule,
    NgbModule,
    ToastyModule,
    NgSelectModule,
    DataTablesModule,
    AngularEditorModule
  ],
  providers: [,
    NgbActiveModal
  ]
})
export class WikiModule { }
