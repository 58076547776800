<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12 col-sm-6 centertb">
            <p class="no-mb">{{ url == 'courses' ? 'Total de cursos': 'Total de capacitaciones' }} <strong>{{totalProducts}}</strong></p>
        </div>
        <div class="col-xs-12 col-sm-6 text-right">
            <div class="action">
                <a class="btn btn-red btn-sm"
                  [routerLink]="[ url == 'courses' ? 'add-course' : 'add-capacitation']">
                  <i class="ti-plus"></i>
                  <span>{{ url == 'courses' ? 'Agregar curso': 'Agregar capacitación' }}</span>
                </a>
            </div>
        </div>
    </div>
    <div class="row products-admin ratio_asos">
        <div class="col-xl-4 col-sm-6" *ngFor="let product of product_list">
            <br>
            <div class="card">
                <div class="card-body product-box">
                    <div class="img-wrapper">
                        <div class="lable-block"><span class="lable3" *ngIf="product.tag == 'new'">new</span> <span
                                class="lable4" *ngIf="product.sale == 'on sale'">on sale</span></div>
                        <div class="front">
                                <a href="javascript:void(0)"><img [src]="[product.ImgFileUrl]" *ngIf="product.ImgFileUrl !== null"
                                    class="img-fluid blur-up lazyload bg-img img" alt=""></a>
                                <a href="javascript:void(0)"><img [src]="'../../../../../assets/images/no-image.png'" *ngIf="product.ImgFileUrl === null"
                                      class="img-fluid blur-up lazyload bg-img img" alt=""></a>
                        </div>
                    </div>
                    <div class="product-detail">
                        <h6>{{product.Title}}</h6>
                        <br>
                        <h4>${{product.DiscountPrice === null || product.DiscountPrice == 0 ? product.UnitPrice : product.DiscountPrice}} {{product.CurrencyCode}} <del *ngIf="product.DiscountPrice !== null">${{product.UnitPrice}} {{product.CurrencyCode}}</del></h4>
                        <br>
                        <h5>{{product.SKU}}</h5>
                        <p class="intro">{{product.Introduction}}</p>
                        <hr>
                        <table>
                            <tbody>
                                <tr>
                                    <td><i class="fa fa-university"></i></td>
                                    <td>{{product.Level}}</td>
                                </tr>
                                <tr>
                                    <td><i class="fa fa-globe"></i></td>
                                    <td>{{product.AccessType}}</td>
                                </tr>
                                <tr>
                                    <td><i class="fa fa-language"></i></td>
                                    <td>{{product.Language}}</td>
                                </tr>
                                <tr>
                                    <td><i class="fa fa-clock-o"></i></td>
                                    <td>{{product.TotalHours}} horas</td>
                                </tr>
                                <tr>
                                    <td><i class="fa fa-eye-slash"></i></td>
                                    <td><span [ngClass]="{'show': product.Active === true, 'hide': product.Active === false}">{{product.Active === true ? 'Publicado' : 'Oculto'}}</span></td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="col-xs-12 fright">
                            <p>
                            <button type="button" class="btn btn-primary mr-1 mat-btn" (click)="product.isCollapsed = !product.isCollapsed"
                                    [attr.aria-expanded]="product.isCollapsed">
                                    <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                            </button>
                            </p>
                            <div [ngbCollapse]="!product.isCollapsed">
                                <div class="product-hover">
                                    <ul class="">
                                        <li>
                                            <button [routerLink]="['show', product.id]" class="btn bt-action" type="button" data-original-title="">{{ url === 'courses' ? 'Ver curso': 'Ver capacitación' }}</button>
                                        </li>
                                        <li>
                                            <button [routerLink]="['edit', product.id]" class="btn bt-action" type="button" data-original-title="">{{ url === 'courses' ? 'Editar curso': 'Editar capacitación' }}</button>
                                        </li>
                                       <li>
                                            <button [routerLink]="['view-programs', product.id]" class="btn bt-action" type="button" data-original-title="">Agregar y/o ver módulos</button>
                                        </li>
                                        <li>
                                            <button [routerLink]="['quiz', product.id]" class="btn bt-action" type="button" data-original-title="">Agregar cuestionario</button>
                                        </li>
                                        <li>
                                            <button (click)="setModalContent(content, product.id, product.Active)" class="btn bt-action" type="button" data-original-title="">{{product.Active === true ? 'Ocultar' : 'Publicar'}}</button>
                                        </li>
                                        <li>
                                            <button (click)="previewCourse(product.id)" class="btn bt-action" type="button" data-original-title="">Previsualizar curso</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content
  let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100"
      id="modal-title">{{active === true ? 'Ocultar contenido' : 'Publicar contenido'}}</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">{{active === true ? 'Se ocultarán todos los datos de este producto.' : 'Se publicarán todos los datos de este producto.'}}</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>{{active === true ? 'Publicado correctamente' : 'Publicado correctamente'}}</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a>
      <button [disabled]="isLoading" type="button"
        class="btn btn-inverse btn-danger" [ngClass]="{'btn-success': active === false, 'btn-danger': active === true}"
        (click)="deleteCourse()">{{active === true ? 'Ocultar' : 'Publicar'}}
        <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
      </button>
    </div>
  </ng-container>
</ng-template>
<ng2-toasty></ng2-toasty>
