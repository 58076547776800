<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-5 p-0 card-left hub-center">
                    <img src="../../../../assets/images/backoffice.png" style="width:350px" alt="">
                </div>
                <div class="col-md-7 p-0 card-right">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <img src="../../../../assets/images/logo1.png" style="text-align: pull-right;" alt="">
                            <ngb-tabset class="tab-coupon mb-0">
                                <ngb-tab>
                                    <ng-template ngbTabTitle><i class="icon-user mr-2"></i>Iniciar sesión</ng-template>
                                    <ng-template ngbTabContent>
                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                            aria-labelledby="account-tab">
                                            <form [formGroup]="loginForm"
                                            (ngSubmit)="onSubmit()"
                                                class="form-horizontal auth-form" novalidate="">
                                                <div class="form-group">
                                                    <input required="" formControlName="email" type="email"
                                                        class="form-control" placeholder="Correo electrónico"
                                                        [ngClass]="{'error': submittedLogin && formState?.email?.errors?.required}">
                                                        <label class="errorRed"
                                                            *ngIf="submittedLogin && formState?.email?.errors?.pattern">
                                                            Ingresa un correo válido.
                                                        </label>
                                                </div>
                                                <div class="form-group">
                                                    <input required="" formControlName="password" type="password"
                                                        class="form-control" placeholder="Contraseña"
                                                        [ngClass]="{'error': submittedLogin && formState?.password?.errors?.required}">
                                                </div>
                                                <button class="btn btn-primary btn-width"
                                                [disabled]="isLoading">
                                                {{ isLoading ? 'Cargando tu cuenta' : 'Ingresar'}}
                                                <i class="fa fa-spinner fa-spin"
                                                [hidden]="!isLoading"></i>
                                                </button>
                                            </form>
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                            </ngb-tabset>
                            <div class="mt-4">
                                <a class="" [routerLink]="['/auth/forgot-password']">¿Olvidaste tu contraseña?</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng2-toasty></ng2-toasty>
