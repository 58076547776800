<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">

            <div class="card">
                <div class="card-body">
                    <div class="container-fluid">
                        <div class="row">
                            <!-- <div class="col-4">
                                <label>Forma de pago</label>
                                <input type="text" id="filterText" placeholder="Buscar por nombre, correo o teléfono" class="form-control">
                            </div> -->
                            <div class="col-4">
                                <label>Fecha inicial</label>
                                <input type="date" id="initialDate" class="form-control" [(ngModel)]="startDate">
                            </div>
                            <div class="col-4">
                                <label>Fecha final</label>
                                <input type="date" id="finalDate" class="form-control" [(ngModel)]="endDate">
                            </div>
                            <div class="col-4 border-left border-dark">
                                <label>Método de pago</label>
                                <select id="paymentMethod" type="text" class="form-control" [(ngModel)]="paymentMethod">
                                    <option value=''>Selecciona una opción</option>
                                    <option value="store" >Efectivo</option>
                                    <option value="card">Tarjeta</option>
                                    <option value="bank_account">Transferencia bancaria</option>
                                </select>
                            </div>
                        </div>
                        <div class="mt-4 text-right">
                            <button value="Buscar" (click)="filter()" class="btn btn-primary">Buscar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <div id="batchDelete" class="">
                        <div class="table-responsive">
                            <div class="d-flex flex-row-reverse mb-4">
                                <button class="btn btn-primary" (click)="exportExcel()">Exportar a Excel</button>
                            </div>
                            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                class=" table table-lg table-hover row-border hover">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Id Orden</th>
                                        <th>Fecha</th>
                                        <th>Status</th>
                                        <th>Método Pago</th>
                                        <th>Banco</th>
                                        <th>Titular</th>
                                        <th>Total</th>
                                        <!-- <th>Acciones</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let transaction of transactions; let i = index">
                                        <td class="td-10">{{transaction.id}}</td>
                                        <td>
                                            <a [routerLink]="['/sales/order/show', transaction.order_id]">
                                                {{transaction.order_id}}
                                            </a>
                                        </td>
                                        <td>{{transaction.operation_date | date:'dd/MM/yyyy'}}</td>
                                        <td class="" width="12%">
                                            <span
                                                [ngClass]="{'completed': transaction.status == 'completed', 'fail': transaction.status == 'fail' || transaction.status == 'failed'}">
                                                {{ transaction.status == 'completed' ? 'Pagado' : 'Fallido' }}
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="transaction.method === 'card'">Tarjeta</span>
                                            <span *ngIf="transaction.method === 'store'">Efectivo</span>
                                            <span *ngIf="transaction.method === 'bank_account'">Transferencia bancaria</span>

                                        </td>
                                        <td>{{transaction.method === 'card' ? transtype[i]?.bank_name:''}}</td>
                                        <td>{{transaction.method === 'card' ? transtype[i]?.holder_name:''}}</td>
                                        <td>{{transaction.amount | currency}} {{transaction.currency}}</td>
                                        <!-- <td class="actions-cell text-center">
                                            <span>
                                                <a [routerLink]="['show', transaction.id]" title="Ver Transacción">
                                                    <i class="fa fa-eye"></i>
                                                </a>
                                            </span>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>


                            <div class="table-responsive noshow">
                                <table class=" table table-lg table-hover row-border hover" id="excel-sales">
                                    <thead>
                                        <tr>
                                            <th>Id Orden</th>
                                            <th>Id Transacción</th>
                                            <th>Fecha</th>
                                            <th>Status</th>
                                            <th>Método Pago</th>
                                            <th>Banco</th>
                                            <th>Titular</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let transaction2 of transactions2; let i = index">
                                            <td>{{transaction2.id}}</td>
                                            <td>{{transaction2.order_id}}</td>
                                            <td>{{transaction2.operation_date | date:'dd/MM/yyyy'}}</td>
                                            <td>
                                                <span
                                                    [ngClass]="{'completed': transaction2.status == 'completed', 'fail': transaction2.status == 'fail'}">
                                                    {{ transaction2.status == 'completed' ? 'Pagado' : 'Fallido' }}
                                                </span>
                                            </td>
                                            <td><span *ngIf="transaction2.method == 'card'"></span>Tarjeta</td>
                                            <td>{{transtype2[i]?.bank_name}}</td>
                                            <td>{{transtype2[i]?.holder_name}}</td>
                                            <td>{{transaction2.amount | currency}} {{transaction2.currency}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="count" [(page)]="page"
                                [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng2-toasty></ng2-toasty>