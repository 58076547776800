import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListUserComponent } from './list-user/list-user.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { UserDetailsComponent } from './user-detail/user-detail.component';
import { ListTeacherComponent } from './list-user-teachers/list-teacher.component';
import { ListAdminComponent } from './list-user-admin/list-admin.component';
import { ListBusinessComponent } from './list-business/list-business.component';
import { OrdersUserComponent } from '../sales/orders/order-user/order-user.component';
import { CoursesOrderComponent } from '../sales/orders/courses-order/course-order.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ListUserComponent,
        data: {
          title: "Miembros",
          breadcrumb: "Miembros"
        }
      },
      {
        path: 'show/:id',
        component: UserDetailsComponent
      },

      /*Business*/
      {
        path: 'business',
        component: ListBusinessComponent,
        data: {
          title: "Empresas",
          breadcrumb: "Empresas"
        }
      },
      {
        path: 'business/show/:id',
        component: UserDetailsComponent
      },
      {
        path: 'business/orders/:id',
        component: OrdersUserComponent,
        data: {
          title: "Pedidos",
          breadcrumb: "Pedidos"
        }
      },
      {
        path: 'business/orders/:id/courses/:orderId',
        component: CoursesOrderComponent,
        data: {
          title: "Detalles del pedido",
          breadcrumb: "Detalles del pedido"
        }
      },

      
      {
        path: 'teachers',
        component: ListTeacherComponent,
        data: {
          title: "Instructores",
          breadcrumb: "Instructores"
        }
      },
      {
        path: 'teachers/create-user',
        component: CreateUserComponent,
        data: {
          title: "Nuevo Instructor",
          breadcrumb: "Nuevo Instructor"
        }
      },
      {
        path: 'teachers/show/:id',
        component: UserDetailsComponent
      },
      {
        path: 'teachers/edit/:id',
        component: CreateUserComponent
      },

      
      {
        path: 'admins',
        component: ListAdminComponent,
        data: {
          title: "Administradores",
          breadcrumb: "Administradores"
        }
      },
      {
        path: 'admins/create-user',
        component: CreateUserComponent,
        data: {
          title: "Nuevo Administrador",
          breadcrumb: "Nuevo Administrador"
        }
      },
      {
        path: 'admins/show/:id',
        component: UserDetailsComponent
      },
      {
        path: 'admins/edit/:id',
        component: CreateUserComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
