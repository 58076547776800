import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastOptions, ToastyConfig, ToastyService } from 'ng2-toasty';
import { AuthenticationService } from 'src/app/components/services/auth/authentication.service';
import { ProductsService } from 'src/app/shared/service/products.service';
import { productDB } from 'src/app/shared/tables/product-list';
import { environment } from 'src/environments/environment';

const frontUrl = environment.frontUrl;
@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  public product_list = []
  public isCollapsed = true;
  public productId: number;
  public active: boolean;
  public deleteIsSuccess = false;
  public isLoading = false;

  public families: any;
  public totalProducts: number;
  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };
  url: string;

  constructor(
    private productService: ProductsService,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private router: Router
  ) {
    this.toastyConfig.theme = 'bootstrap';

  }

  ngOnInit() {
    this.url = this.router.url.split("/").pop();
    if( this.url == 'courses' ){
      this.subs.push(
        this.productService
          .getCourses()
          .pipe(
            catchError(err => this.authService.handleUnauthorizedError(err))
          )
          .subscribe(data => {
            this.product_list = data;
            this.totalProducts = this.product_list.length;
          })
      );
    }else{
      this.subs.push(
        this.productService
          .getCapacitations()
          .pipe(
            catchError(err => this.authService.handleUnauthorizedError(err))
          )
          .subscribe(data => {
            this.product_list = data;
            this.totalProducts = this.product_list.length;
          })
      );
    }
  }

  previewCourse(id: number){
    let uuid = "a4cd4f5f-f735-435d-ad1c-73c9053e4498";
    this.subs.push(
      this.productService
        .setCoursePreview(uuid, id)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          window.open(frontUrl + "course/"+uuid);
        })
    );

  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  setModalContent(content: any, productId: number, status: boolean): void {
    this.productId = productId;
    this.active = status;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public deleteCourse() {
    this.isLoading = true;
    if(this.active){
      this.subs.push(
        this.productService.deleteProduct(this.productId)
          .subscribe(resolve => {
            if (resolve.Message == 'Status Changed') {
              this.deleteIsSuccess = true;
              setTimeout(() => {
                this.modalService.dismissAll();
              }, 1000);
              setTimeout(() => {
                this.isLoading = false;
                this.ngOnInit();
              }, 2000);
            }
            this.deleteIsSuccess = false;
          })
      );
    }else{
      this.subs.push(
        this.productService.validateActivation(this.productId)
          .subscribe(resolve => {
            if (resolve.Message == 'Producto publicado exitosamente') {
              this.deleteIsSuccess = true;
              setTimeout(() => {
                this.modalService.dismissAll();
              }, 1000);
              setTimeout(() => {
                this.isLoading = false;
                this.ngOnInit();
              }, 2000);
            }else{
              this.handleError(resolve)
              this.isLoading = false;
            }
            this.deleteIsSuccess = false;
          })
      );
    }
  }


  private handleError(error:any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: error.Message,
      showClose: true,
      timeout: 3500
    };

    this.toastyService.error(toastOptions);

    return [];
  }
  
}
