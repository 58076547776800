<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-lg-6 pb-15">
                    <div class="col-xs-12 col-lg-12">
                        <ng-select name="catalogId" id="catalogId" [(ngModel)]="selectedCatalog" (change)="getCatalogValues()" [ngModelOptions]="{standalone: true}">
                            <ng-option *ngFor="let catalog of catalogs" [value]="catalog.CatalogName">
                                {{catalog.AliasName}}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-lg-6 pb-15">
                    <div class="btn-popup pull-right">
                        <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                            data-target="#exampleModal" (click)="open(content)">Agregar</button>
                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <h5 class="modal-title f-w-600" id="exampleModalLabel">Agregar Valor</h5>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form class="needs-validation" [formGroup]="featureForm" (ngSubmit)="onSubmit()" novalidate>
                                    <div class="form">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1" class="">Catálogo:</label>
                                            <ng-select name="catalogId" id="catalogId" [formControl]="featureForm.controls['CatalogName']" [ngClass]="{'error': submitted && formState?.CatalogName?.errors?.required}">
                                                <ng-option *ngFor="let catalog of catalogs" [value]="catalog.CatalogName">
                                                    {{catalog.AliasName}}
                                                </ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="form-group">
                                            <label for="validationCustom01" class="mb-1">Valor:</label>
                                            <input class="form-control" id="validationCustom01" [formControl]="featureForm.controls['ValueAlpha']" type="text" maxlength="350"
                                            [ngClass]="{'error': submitted && formState?.ValueAlpha?.errors?.required}">
                                            <label class="errorRed"
                                                *ngIf="submitted && formState?.ValueAlpha?.errors?.maxlength">
                                                Máximo 350 carácteres.
                                            </label>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button class="btn btn-outline-light" type="button" data-dismiss="modal"
                                            (click)="modal.dismiss('Cross click')">Cancelar</button>
                                        <button type="submit" class="btn btn-primary">Guardar</button>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 pl-15" *ngIf="values.length == 0">
                <p>Seleccione un catálogo de la lista.</p>
            </div>
            <div id="batchDelete" class="" *ngIf="values.length !== 0">
                <div class="table-responsive">
                    <table datatable
                    [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger"
                    class=" table table-lg table-hover row-border hover">
                    <thead>
                        <tr>
                        <th>Valor</th>
                        <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let value of values">
                        <td>{{value.ValueAlpha}}</td>
                        <td class="actions-cell">
                            <span (click)="openEdit(contentEdit, value)"
                            title="Editar Valor">
                            <i class="fa fa-edit"></i>
                            </span>
                            <span (click)="setModalContent(contentDelete, value.id)"
                            title="Borrar valor">
                            <i class="fa fa-trash"></i>
                            </span>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
<ng2-toasty></ng2-toasty>

<ng-template #contentEdit let-modal>
    <div class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModalLabel">Editar Valor</h5>
        <button type="button" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form class="needs-validation" [formGroup]="featureForm" (ngSubmit)="onUpdate()" novalidate>
            <div class="form">
                <div class="form-group">
                    <label for="exampleFormControlSelect1" class="">Catálogo:</label>
                    <ng-select name="catalogId" id="catalogId" [formControl]="featureForm.controls['CatalogName']">
                        <ng-option *ngFor="let catalog of catalogs" [value]="catalog.CatalogName">
                            {{catalog.AliasName}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1">Valor:</label>
                    <input class="form-control" id="validationCustom01" [formControl]="featureForm.controls['ValueAlpha']" type="text">
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-outline-light" type="button" data-dismiss="modal"
                    (click)="modal.dismiss('Cross click')">Cancelar</button>
                <button type="submit" class="btn btn-primary">Editar</button>
            </div>
        </form>
    </div>

</ng-template>

<ng-template #contentDelete
  let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100"
      id="modal-title">Eliminar Valor</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">Se eliminirá el valor del catálogo.</p>
      <p class="no-mrg">Esta acción no se puede deshacer.</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>Valor borrado correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a>
      <button type="button"
        class="btn btn-inverse btn-danger"
        (click)="deleteFeature()">Eliminar</button>
    </div>
  </ng-container>
</ng-template>
