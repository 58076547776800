import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ProgramsService } from 'src/app/shared/service/programs.service';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';


@Component({
  selector: 'app-program-detail',
  templateUrl: './program-detail.component.html',
  styleUrls: ['./program-detail.component.scss']
})
export class ProgramsDetailsComponent implements OnInit, OnDestroy {

  private subs: Array<Subscription> = [];
  public program: any;
  public topics = [];
  public programId: number;
  public requiredFileType: string;

  public viewLoaderVideo = false;
  public previewVideo: any;
  public responseVideo: string;
  public fileNameVideo = '';

  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject()

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private programsService: ProgramsService,
  ) {
  }

  onVideoSelected(event) {
    const file:File = event.target.files[0];

    if(file.type == 'video/mp4' || file.type == 'video/mov' || file.type == 'video/wmv' || file.type == 'video/avi' || file.type == 'video/flv'){
      this.viewLoaderVideo = true;
      this.fileNameVideo = 'Cargando video...';
      setTimeout(() => {
        if (file) {
          const formData = new FormData();
          formData.append('videoupload' , file);
          formData.append('name' , file.name);
  
          this.subs.push(
            this.programsService.uploadVideo(formData, this.program.id)
              .subscribe(resolve => {
                if (resolve.Responses== 'Este archivo se esta renderizando en Vimeo') {
                  this.responseVideo = resolve.FileUrl;
                  this.viewLoaderVideo = false;
                  this.previewVideo = "assets/images/check.png";
                  this.fileNameVideo = 'Video cargado correctamente';
                }
              })
          );
        }
      }, 1800);
    }else{
      this.NoTypeVideo()
    }
  }


  ngOnInit() {
    this.subs.push(
      this.activatedRoute.paramMap.pipe(
        map(params => params.get('programId')),
        switchMap(id => this.programsService.getProgram(id)),
        catchError(error => {
          this.router.navigate(['/programs']);
          return throwError(error);
        })
      ).subscribe(program => {
        this.program = program;
        this.topics = program?.topic;
        this.topics = this.topics.filter(topic => topic.Status)
        this.dtTrigger.next();
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  onSubmit() {
  }

  private NoTypeVideo() {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes subir un video en los siguientes formatos mov, wmv, avi, flv o mp4',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);
  }

}
