import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastOptions, ToastyConfig, ToastyService } from 'ng2-toasty';
import { Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NewsService } from 'src/app/shared/service/news.service';
import { ProductsService } from 'src/app/shared/service/products.service';
import { environment } from 'src/environments/environment';
import { CourseModel } from '../../products/product.models';
import { NewsModel } from '../news.models';

const BASE_URL: string = environment.API_URL;
@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.scss']
})
export class AddNewsComponent implements OnInit {
  public newsForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public onEdit: boolean;
  public productId: number;
  public id: number;
  public requiredFileType: string;
  public ImgfileName = '';
  public responseImgFile: string;
  public previewImg: any;
  public viewLoaderImg = false;

  public viewLoaderFile = false;
  public previewFile: any;
  public responseFile: string;
  public fileName = '';

  public ImgfileName2 = '';
  public viewLoaderImg2 = false;
  public responseImgFile2: string;
  public previewImg2: any;
  public footer1: string = '';


  public prevImages = [];
  public footers = [];

  public categories : any;
  public imageUrl:any;

  public image = [];
  public images: any;
  public imagesresult: string;
  public showImages: boolean;
  public array: any;
  public aditionalImages = [];

  public counter: number = 1;
  public url = [{
    img: "assets/images/photo.png",
  }
  ]
  private subs: Array<Subscription> = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private newsService: NewsService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private productService: ProductsService
  ) {
    this.toastyConfig.theme = 'bootstrap';
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: 'Montserrat',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
      { class: 'montserrat', name: 'Montserrat' }
    ],
    sanitize: true,
    toolbarHiddenButtons: [
      ['insertImage',
        'insertVideo']
    ]
  };

  onImgSelected(event, num) {
    const file: File = event.target.files[0];

    if (file.type == 'image/png' || file.type == 'image/jpeg') {

      this.viewLoaderImg = num == 1 ? true : false;
      this.viewLoaderImg2 = num == 2 ? true : false;
      setTimeout(() => {
        if (file) {
          const formData = new FormData();
          formData.append('profileImg', file);
          this.subs.push(
            this.newsService.uploadImg(formData)
              .subscribe(resolve => {
                if (resolve.FileUrl !== null) {
                  if (this.responseImgFile == undefined && num == 1) {
                    this.responseImgFile = resolve.ImageUrl;
                    this.viewLoaderImg = false;
                    this.viewBase64(file).then(image => {
                      this.previewImg = image;
                    })
                    this.ImgfileName = file.name;
                  } else if (this.responseImgFile2 == undefined && num == 2) {
                    this.responseImgFile2 = resolve.ImageUrl;
                    if(this.onEdit){
                      this.aditionalImages.push({ image: this.responseImgFile2, footer: '' })
                    }else{
                      this.image.push({ image: this.responseImgFile2, footer: '' });
                      this.images = [...this.image];
                      this.imagesresult = JSON.stringify(this.images);
                      this.viewBase64(file).then(image => {
                        this.ImgfileName2 = file.name;
                        this.prevImages.push({ previewImg2: image, ImgfileName2: file.name })
                      })
                    }
                    this.ImgfileName2 = file.name;
                    this.viewLoaderImg2 = false;
                    this.responseImgFile2 = undefined;
                  }

                }

              })
          );
        }
      }, 1800);
    } else {
      this.NoTypeImg()
    }
  }



  viewBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };
    } catch (e) {
      return null;
    }
  })

  onFileSelected(event) {
    const file: File = event.target.files[0];

    if (file.type == 'application/pdf') {
      this.viewLoaderFile = true;
      this.fileName = 'Cargando archivo...';
      setTimeout(() => {
        if (file) {
          const formData = new FormData();
          formData.append('Namefile', file);
          formData.append('Titulo', file.name);
          formData.append('type', 'Document');

          this.subs.push(
            this.newsService.uploadFile(formData)
              .subscribe(resolve => {
                if (resolve.FileUrl !== null) {
                  this.responseFile = resolve.FileUrl;
                  this.viewLoaderFile = false;
                  this.previewFile = "assets/images/check.png";
                  this.fileName = 'Archivo cargado correctamente';
                }
              })
          );
        }
      }, 1800);
    } else {
      this.NoTypeFile()
    }
  }

  ngOnInit() {
    this.subs.push(
      this.activatedRoute.paramMap.subscribe((data: any) => {
        if (data.params.id) {
          this.productId = data.params.id;
          this.onEdit = true;
          this.newsService.getNew(data.params.id)
            .subscribe(product => this.setValues(product));
          this.newsForm = this.fb.group({
            Title: ['', [Validators.required]],
            ShortDescription: ['', [Validators.required]],
            MainBody: ['', [Validators.required]],
            Type: ['', [Validators.required]],
            Author: ['', [Validators.required]],
            Source: ['', Validators.required],
          })
        } else {
          this.onEdit = false;
          this.newsForm = this.fb.group({
            Title: ['', [Validators.required]],
            ShortDescription: ['', [Validators.required]],
            MainBody: ['', [Validators.required]],
            Type: ['', [Validators.required]],
            Author: ['', [Validators.required]],
            Source: ['', Validators.required],
          })
        }
        this.productService
          .getCategories()
          .subscribe(data => {
            this.categories = data;
          })
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public get formState() {
    return this.newsForm.controls
  }

  private setValues(product: any): void {
    const values = this.newsService.mapRequiredValues(product);
    this.id = values.id;
    this.imageUrl = values.ImageUrl;
    this.responseImgFile = values.ImageUrl;
    if (values.aditionalImg) {
      this.aditionalImages = JSON.parse(values.aditionalImg);
      this.images = JSON.parse(values.aditionalImg);
      this.aditionalImages.forEach(item => {
        this.footers.push(item.footer);
      })
    }
    const { controls } = this.newsForm;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.newsForm.controls[value].setValue(values[value]);
      }
    }
  }

  private buildNew(): NewsModel {
    return {
      Title: this.newsForm.controls['Title'].value,
      ShortDescription: this.newsForm.controls['ShortDescription'].value,
      MainBody: this.newsForm.controls['MainBody'].value,
      Type: this.newsForm.controls['Type'].value,
      Author: this.newsForm.controls['Author'].value,
      Source: this.newsForm.controls['Source'].value,
      ImageUrl: this.responseImgFile,
      aditionalImg: this.images ? JSON.stringify(this.images) : ''
    }
  }

  private buildNewUpdated(): NewsModel {
    return {
      Title: this.newsForm.controls['Title'].value,
      ShortDescription: this.newsForm.controls['ShortDescription'].value,
      MainBody: this.newsForm.controls['MainBody'].value,
      Type: this.newsForm.controls['Type'].value,
      Author: this.newsForm.controls['Author'].value,
      Source: this.newsForm.controls['Source'].value,
      ImageUrl: this.responseImgFile ? this.responseImgFile : this.imageUrl,
      aditionalImg: this.aditionalImages ? JSON.stringify(this.aditionalImages) : ''
    }
  }

  onSubmit() {
    this.submitted = true;

    if( !this.responseImgFile ){
      this.handleImageError("Debes seleccionar una imagen al encabezado");
      return;
    } 

    if (this.images) {
      this.images.forEach((item, idx) => {
        console.log(this.footers[idx]);
        if( this.footers[idx] ){
          item.footer = this.footers[idx];
        }else{
          this.handleImageError("Todas las imagenes deben llevar pie de foto");
          return;
        }
      })
    }else{
      this.handleImageError("Debes seleccionar una imagen al cuerpo de la noticia");
      return;
    }

    if (this.aditionalImages){
      this.aditionalImages.forEach((item, idx) => {
        if( this.footers[idx] ){
          item.footer = this.footers[idx];
        }else{
          this.handleImageError("Todas las imagenes deben llevar pie de foto");
          return;
        }
      })
    }else{
      this.handleImageError("Debes seleccionar una imagen al cuerpo de la noticia");
      return;
    }

    if( this.newsForm.get("ShortDescription").value == "" ){
      this.handleImageError("Debes agregar introducción de la noticia");
      return;
    }

    if( this.newsForm.get("MainBody").value == "" ){
      this.handleImageError("Debes agregar cuerpo a la noticia");
      return;
    }

    if (this.newsForm.invalid) {
      this.handleFormError();
      return;
    }

    if (this.onEdit) {
      this.updateNew();
    } else {
      this.createNew();
    }
  }
  

  createNew() {

    const news: NewsModel = this.buildNew();
    this.isLoading = true;

    this.subs.push(
      this.newsService.createNews(news)
        .pipe(
          map(() => {
            this.handleCourseCreation();
            setTimeout(() => {
              this.router.navigateByUrl('news');
            }, 1800);
          }),
          catchError(error => this.handleError(error))
        )
        .subscribe()
    );
  }

  updateNew() {
    const news: NewsModel = this.buildNewUpdated();
    this.isLoading = true;
    this.subs.push(
      this.newsService.updateNews(this.productId, news)
        .pipe(
          map(() => {
            this.handleCourseEdition();
            setTimeout(() => {
              this.router.navigateByUrl('news');
            }, 1800);
          }),
          catchError(error => this.handleError(error))
        )
        .subscribe()
    );
  }

  private handleCourseCreation() {
    const toastOptions: ToastOptions = {
      title: 'Noticia',
      msg: 'Noticia creado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  handleImageError(arg0: string) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: arg0,
      showClose: true,
      timeout: 3500
    };
    this.isLoading = false;
    this.toastyService.error(toastOptions);
  }

  private handleCourseEdition() {
    const toastOptions: ToastOptions = {
      title: 'Noticia',
      msg: 'Noticia actualizado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private handleError(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudo crear la noticia',
      showClose: true,
      timeout: 2000
    };
    this.isLoading = false;
    this.toastyService.error(toastOptions);

    return [];
  }

  private handleFormError() {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes llenar todos los campos',
      showClose: true,
      timeout: 2000
    };
    this.isLoading = false;
    this.toastyService.error(toastOptions);

    return [];
  }

  private NoTypeImg() {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes subir una imagen de tipo png o jpg',
      showClose: true,
      timeout: 1800
    };

    this.toastyService.error(toastOptions);
  }

  private NoTypeFile() {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes subir un archivo de tipo pdf',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.error(toastOptions);
  }

}
