import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/components/services/auth/authentication.service';
import { ProductsService } from 'src/app/shared/service/products.service';
import { digitalListDB } from 'src/app/shared/tables/digital-list';

@Component({
  selector: 'app-digital-list',
  templateUrl: './digital-list.component.html',
  styleUrls: ['./digital-list.component.scss']
})
export class DigitalListComponent implements OnInit {
  public product_list = []
  public isCollapsed = true;
  public productId: number;
  public active: boolean;
  public deleteIsSuccess = false;
  public isLoading = false;

  public transmitionUrl: any;

  public families: any;
  public totalProducts: number;
  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  constructor(
    private productService: ProductsService,
    private authService: AuthenticationService,
    private toastyService: ToastyService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
    this.subs.push(
      this.productService
        .getWebinars()
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.product_list = data;
          this.totalProducts = this.product_list.length;
        })
    );
  }

  saveTransmition(){
    this.isLoading = true;
    // console.log(this.transmitionUrl);
    let data = {
      id: this.productId,
      FileUrl: this.transmitionUrl
    }
    this.subs.push(
      this.productService.updateCourse(this.productId, data)
        .subscribe(resolve => {
          this.isLoading = false;
          this.handleShowMessage();
          this.modalService.dismissAll();
          window.location.reload();
        })
    );
  }


  public setModalContent(content: any, productId: number, status: boolean): void {
    this.productId = productId;
    this.active = status;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  private handleShowMessage() {
    const toastOptions: ToastOptions = {
      title: 'Publicado',
      msg: 'Se agrego URL con éxito',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  public deleteWebinar() : void {
    this.isLoading = true;
    this.subs.push(
      this.productService.deleteProduct(this.productId)
        .subscribe(resolve => {
          if (resolve.Message == 'Status Changed') {
            this.deleteIsSuccess = true;
            setTimeout(() => {
              this.modalService.dismissAll();
            }, 1000);
            setTimeout(() => {
              this.isLoading = false;
              this.ngOnInit();
            }, 2000);
          }
          this.deleteIsSuccess = false;
        })
    );
  }

}
