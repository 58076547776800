<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-4">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details text-center">
                        <img src="assets/images/dashboard/profile-user.png" alt=""
                            class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                        <h5 class="f-w-600 mb-0">{{user?.firstName}} {{user?.lastName}}</h5>
                        <span>{{user?.email}}</span>
                        <hr>
                        <span>{{user?.phoneNumber}}</span>
                        <hr>
                        <span>Ciudad: {{user?.city}}</span><br>
                        <span>País: {{user?.country ? user?.country : 'Sin país'}}</span><br>
                        <span>Fecha de nacimiento: {{user?.birthday | date:'dd/MM/yyyy'}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <div class="card tab2-card">
                <div class="card-body">
                    <div class="row" *ngIf="user?.roles == 'student'">
                        <div class="col-xl-3 col-md-6 xl-50">
                            <div class="card o-hidden widget-cards">
                                <div class="bg-primary card-body">
                                    <div class="media static-top-widget row">
                                        <div class="icons-widgets col-4">
                                            <div class="align-self-center text-center">
                                                <app-feather-icons [icon]="'shopping-cart'" class="font-primary"></app-feather-icons>
                                            </div>
                                        </div>
                                        <div class="media-body col-8"><span class="m-0">Nº Pedidos</span>
                                            <h4 class="mb-0"><span class="counter">{{countOrders}}</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-6 xl-50">
                            <div class="card o-hidden widget-cards">
                                <div class="bg-danger card-body">
                                    <div class="media static-top-widget row">
                                        <div class="icons-widgets col-4">
                                            <div class="align-self-center text-center">
                                                <app-feather-icons [icon]="'book'" class="font-danger"></app-feather-icons>
                                            </div>
                                        </div>
                                        <div class="media-body col-8"><span class="m-0">Nº Cursos</span>
                                            <h4 class="mb-0"><span class="counter">{{countCourses}}</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ngb-tabset class="tab-coupon profile-tabs">
                        <ngb-tab>
                            <ng-template ngbTabTitle>
                                <app-feather-icons [icon]="'user'"></app-feather-icons>Perfil</ng-template>
                            <ng-template ngbTabContent>
                                <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                                    aria-labelledby="top-profile-tab">
                                    <h5 class="f-w-600"> Editar Perfil</h5>
                                    <form [formGroup]="form"
                                    (ngSubmit)="updateUser()" class="form-horizontal auth-form" novalidate="">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Nombre</label>
                                                    <input required="" type="text" class="form-control" formControlName="firstName"
                                                    [ngClass]="{'error': submittedProfile && formState?.firstName?.errors?.required}">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Apellido</label>
                                                    <input required="" type="text" class="form-control" formControlName="lastName"
                                                    [ngClass]="{'error': submittedProfile && formState?.lastName?.errors?.required}">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Correo electrónico</label>
                                                    <input type="email" required="" class="form-control" formControlName="email"
                                                    [ngClass]="{'error': submittedProfile && formState?.lastName?.errors?.required}">
                                                    <label class="errorRed"
                                                        *ngIf="submittedProfile && formState?.email?.errors?.pattern">
                                                        Ingresa un correo válido.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Teléfono</label>
                                                    <input type="number" class="form-control" formControlName="phoneNumber" oninput="validity.valid||(value='');"
                                                    [ngClass]="{'error': submittedProfile && formState?.phoneNumber?.errors?.required}">
                                                    <label class="errorRed"
                                                        *ngIf="submittedProfile && formState?.phoneNumber?.errors?.pattern">
                                                        Ingresa sólo 10 números.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Ciudad</label>
                                                    <input required="" type="text" class="form-control" formControlName="city"
                                                    [ngClass]="{'error': submittedProfile && formState?.city?.errors?.required}">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>País</label>
                                                    <input required="" type="text" class="form-control" formControlName="country"
                                                    [ngClass]="{'error': submittedProfile && formState?.country?.errors?.required}">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Fecha de nacimiento</label>
                                                    <div class="input-group nopadd">
                                                        <input class="form-control" type="date" placeholder="dd/mm/aaaa" formControlName="birthday"
                                                        [ngClass]="{'error': submittedProfile && formState?.birthday?.errors?.required}">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-12">
                                            <div class="text-right mrg-top-5">
                                                <button type="submit"
                                                class="btn btn-inverse btn-red mrg-right-15">
                                                {{ isLoading ? 'Actualizando perfil' : 'Actualizar perfil'}}
                                                <i class="fa fa-spinner fa-spin"
                                                [hidden]="!isLoading"></i>
                                                </button>
                                            </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab>
                            <ng-template ngbTabTitle>
                                <app-feather-icons [icon]="'unlock'"></app-feather-icons>Contraseña</ng-template>
                            <ng-template ngbTabContent>
                                <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                                    aria-labelledby="top-profile-tab">
                                    <h5 class="f-w-600"> Actualizar contraseña</h5>
                                    <form [formGroup]="formpassword" class="form-horizontal auth-form"
                                    (ngSubmit)="onChangePassword()">
                                        <div class="row">
                                            <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Correo electrónico</label>
                                                <input type="email" class="form-control" formControlName="email"
                                                [attr.disabled]="disabled === true"
                                                [ngClass]="{'error': submitted && formStatePass?.email?.errors?.required || submitted && formStatePass?.email?.errors?.pattern}">
                                                <span *ngIf="submitted && formStatePass?.email?.errors" class="text-danger">
                                                    Ingresa un correo válido.
                                                </span>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Nueva contraseña</label>
                                                <input type="password" class="form-control" formControlName="password" minlength="8"
                                                [ngClass]="{'error': submitted && formStatePass?.password?.errors?.required}">
                                                <span *ngIf="submitted && formStatePass?.password?.errors" class="text-danger">
                                                    La contraseña debe tener mínimo 8 caracteres.
                                                </span>
                                            </div>
                                            </div>
                                            <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Confirmar nueva contraseña</label>
                                                <input type="password" class="form-control" formControlName="confirmedPassword"
                                                [ngClass]="{'error': submitted && formStatePass?.confirmedPassword?.errors?.required}">
                                                <span *ngIf="submitted && formStatePass?.confirmedPassword?.errors" class="text-danger">
                                                    Las contraseñas no coinciden.
                                                </span>
                                                <br>
                                                <span *ngIf="submitted && formStatePass?.confirmedPassword?.errors" class="text-danger">
                                                    La contraseña debe tener mínimo 8 caracteres.
                                                </span>
                                            </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-12">
                                            <div class="text-right mrg-top-5">
                                                <button type="submit"
                                                class="btn btn-inverse btn-red mrg-right-15">
                                                {{ isLoadingPass ? 'Actualizando contraseña' : 'Actualizar contraseña'}}
                                                <i class="fa fa-spinner fa-spin"
                                                [hidden]="!isLoadingPass"></i>
                                                </button>
                                            </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>
                       </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div>

<ng2-toasty></ng2-toasty>

