import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { UserModel } from '../auth.models';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { NotificationType } from '../../interfaces/notification.enums';
import { ClientRoles } from '../../services/auth/auth.models';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public loginForm: FormGroup;
  public registerForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public submittedLogin = false;

  private subs: Array<Subscription> = [];

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig
) {
    this.toastyConfig.theme = 'bootstrap';
}

  owlcarousel = [
    {
      title: "Hub de seguridad",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Hub de seguridad",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Hub de seguridad",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    }
  ]
  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true
  };

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', Validators.required]
    })

    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      businessname: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', Validators.required],
      confirmedPassword: ['', [Validators.required]]
    })

  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public get formState() {
    return this.loginForm.controls;
  }

  private validatePasswords(): void {
    const password: string = this.registerForm.get('password').value;
    const confirmPassword: string = this.registerForm.get('confirmedPassword').value;
    // console.log(password, confirmPassword);

    if (password !== confirmPassword) {
        this.registerForm.controls['confirmedPassword'].setErrors({ incorrect: true });
    };
  }

  private buildUser(): UserModel {
    return {
        firstName: this.registerForm.controls['firstName'].value,
        lastName: this.registerForm.controls['lastName'].value,
        businessname: this.registerForm.controls['businessname'].value,
        password: this.registerForm.controls['password'].value,
        email: this.registerForm.controls['email'].value
    }
  }

  private loginUser(): UserModel {
    return {
        email: this.loginForm.controls['email'].value,
        password: this.loginForm.controls['password'].value
    }
  }

  private handleError(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudo crear el nuevo establecimiento',
      showClose: true,
      timeout: 2000
    };
    this.isLoading = false;
    this.toastyService.error(toastOptions);

    return [];
  }

  private handleUserCreation() {
    const toastOptions: ToastOptions = {
      title: 'Usuario',
      msg: 'Usuario creado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  onSubmit() {
    this.submittedLogin = true;

    if (this.loginForm.invalid) {
      return;
    }

    const user: UserModel = this.loginUser();
    this.isLoading = true;
    this.subs.push(
      this.authService.login(user)
        .pipe(
          map((data: any) => {
            // console.log( data );
            if( data.Message ){ 
              this.sendNotification('Error al acceder', data?.Message, NotificationType.error); 
              this.isLoading = false;
              return;
            }
            this.router.navigateByUrl("/dashboard");
          }),
          catchError(err => {
            this.isLoading = false;
            this.sendNotification('Error al acceder', err.error?.message, NotificationType.error);
            return throwError(err);
          })
        ).subscribe( )
    );
  }

  registerUser() {
    this.submitted = true;
    this.validatePasswords();

    if (this.registerForm.invalid) {
        return;
    }
    const user: UserModel = this.buildUser();
    this.isLoading = true;
    this.subs.push(
        this.authService.signUp(user)
        .pipe(
        map(() => {
            this.handleUserCreation();
            setTimeout(() => {
            window.location.reload();
            }, 1800);
        }),
        catchError(error => this.handleError(error))
        )
        .subscribe()
    );
  }

  private sendNotification(title: string = 'Hub de seguridad', message: string, type: string): void {
    const toastOptions: ToastOptions = {
      title,
      msg: message,
      showClose: true,
      timeout: 3500,
      theme: 'bootstrap'
    };

    this.toastyService[type](toastOptions);
  }

}
