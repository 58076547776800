<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="row product-adding">
                <div class="add-product-form">
                    <div class="col-xl-12">
                        <form class="needs-validation" [formGroup]="wikiForm" (ngSubmit)="onSubmit()" novalidate>
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom01" class="col-form-label pt-0">Tema</label>
                                                <input class="form-control" id="validationCustom01" type="text"
                                                    [formControl]="wikiForm.controls['Topic']"
                                                    [ngClass]="{'error': submitted && formState?.Topic?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom02"
                                                    class="col-form-label pt-0">Categoría</label>
                                                <ng-select name="Category" id="validationCustom02"
                                                    [formControl]="wikiForm.controls['Type']"
                                                    [ngClass]="{'error': submitted && formState?.Type?.errors?.required}">
                                                    <ng-option *ngFor="let category of categories"
                                                        [value]="category.ValueAlpha">
                                                        {{category.ValueAlpha}}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="form-group">
                                <div class="row">

                                    <div class="col-xs-6 col-lg-10">
                                        <label class="col-form-label pt-0">Subtemas</label>
                                    </div>
                                </div>
                                
                                <div class="card">
                                    <div class="card-body">
                                        <div class="container-fluid">
                                            <form [formGroup]="subtopicForm" class="row">
                                                <div class="col-6">
                                                    <div >
                                                        <label for="Subtopic" class="col-form-label pt-0">Nombre</label>
                                                        <input type="text" [formControl]="subtopicForm.controls['title']" class="form-control">
                                                    </div>
                                                    <div class="mt-4">
                                                        <label for="Imagen" class="col-form-label pt-0">Imagen</label>
                                                        <div class="file-upload">
                                                            <div>
                                                                <input type="file" class="file-input" [accept]="requiredFileType"
                                                                    (change)="onImgSelected($event,2)" #fileImgUpload2>
                                                                {{ImgfileName || "Seleccionar imagen... "}}

                                                                <button class="btn btn-primary mat-btn" (click)="fileImgUpload2.click()">
                                                                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                                                                </button>
                                                            </div>
                                                            <div *ngIf="ImgfileName">

                                                                <table class="table table-responsive">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Imagen</th>
                                                                            <th>Pie de foto</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngIf="responseImgFile">
                                                                            <td>
                                                                                <div class="add-product">
                                                                                    <div class="box-input-file">
                                                                                        <img class="imgUpload" alt="" [src]="responseImgFile">
                                                                                    </div>
                                                                                </div>

                                                                            </td>
                                                                            <td>
                                                                                <textarea [formControl]="subtopicForm.controls['footerImg']" name="" cols="30"
                                                                                    rows="2"></textarea>

                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <div class="add-product">
                                                                                <div *ngIf="viewLoaderImg" class="spinner-border text-danger"
                                                                                    role="status">
                                                                                    <span class="sr-only">Loading...</span>
                                                                                </div>
                                                                            </div>
                                                                        </tr>

                                                                    </tbody>

                                                                </table>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="validationCustom06"
                                                            class="col-form-label pt-0">Contenido</label>
                                                        <br>
                                                        <angular-editor [formControl]="subtopicForm.controls['content']"
                                                        [placeholder]="'Escribe el contenido aquí...'" [config]="editorConfig">
                                            </angular-editor>
                                                    </div>
                                                </div>
                                                <!-- <div class="offset-7 col-xs-5 col-lg-5 center-vertical">
                                                    <button type="button" class="btn btn-primary" (click)="addSubtopic()"><span
                                                            class="mat-more">+ Agregar subtema</span></button>
                                                </div> -->
                                                <div class="row w-100 text-right">
                                                    <div class="product-buttons justify-content-end col-12">
                                                        <button class="btn btn-primary" (click)="addSubtopic()">
                                                            Agregar subtema
                                                            <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>

                                <div class="card" *ngIf="subtopics.length > 0">
                                    <div class="container py-3" *ngFor="let sub of subtopics; let i = index;">
                                        <div class="row px-3">
                                            <h3>{{sub.title}}</h3>
                                        </div>
                                        <div class="row">
                                            <div class="col-9">
                                                <p [innerHTML]="sub.content"></p>
                                            </div>
                                            <div class="col-3">
                                                <img [src]="sub.image.image" alt="sub.title" width="100%">
                                                <p class="pt-3">{{ sub.image.footer }}</p>
                                            </div>
                                        </div>
                                        <div class="row text-right px-3">
                                            <a (click)="deleteSubTopic(i)" class="btn btn-sm btn-danger">Eliminar</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="form-group row w-100 text-right mb-0">
                                <div class="product-buttons">
                                    <button class="btn btn-primary" [disabled]="isLoading" *ngIf="onEdit == false">
                                        {{ isLoading ? 'Creando wiki' : 'Agregar wiki'}}
                                        <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                    </button>
                                </div>

                                <div class="product-buttons">
                                    <button type="submit" class="btn btn-primary" *ngIf="onEdit == true">
                                        {{ isLoading ? 'Editando wiki' : 'Editar wiki'}}
                                        <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng2-toasty></ng2-toasty>
