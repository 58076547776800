import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, Subject, throwError } from 'rxjs';
import { UserModel } from '../../auth/auth.models';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { UsersService } from '../../services/users/users.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  public accountForm: FormGroup;
  public isLoading = false;
  public submitted = false;

  private subs: Array<Subscription> = [];
  public user: any;
  public course: any;
  public courses: any;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();

  public dtOptionsCourses: DataTables.Settings = {};
  public dtTriggerCourses: Subject<any> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private usersService: UsersService,
  ) {
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      language: {
        emptyTable: '',
        zeroRecords: 'No hay coincidencias',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
      order:[[0, 'desc']]
    };

    this.subs.push(
      this.activatedRoute.paramMap.pipe(
        map(params => params.get('id')),
        switchMap(id => this.authService.getUser(id)),
        catchError(error => {
          this.router.navigate(['/users']);
          return throwError(error);
        })
      ).subscribe(user => {
        this.user = user;
        if (this.user.roles == 'student'){
          this.subs.push(
            this.activatedRoute.paramMap.pipe(
              map(params => params.get('id')),
              switchMap(id => this.usersService.getStudent(id)),
              catchError(error => {
                this.router.navigate(['/users']);
                return throwError(error);
              })
            ).subscribe(courses => {
              this.course = courses;
              this.dtTrigger.next();
            })
          );
        }else{
          return;
        }
      })
    );
}

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public editDriver(): void {
    this.router.navigate(['/drivers/edit/', this.user.id]);
  }

  public get formState() {
    return this.accountForm.controls
  }

  private validatePasswords(): void {
    const password: string = this.accountForm.get('password').value;
    const confirmPassword: string = this.accountForm.get('confirmedPassword').value;

    if (password !== confirmPassword) {
        this.accountForm.controls['confirmedPassword'].setErrors({ incorrect: true });
    };
  }

  private buildUser(): UserModel {
    return {
        firstName: this.accountForm.controls['firstName'].value,
        lastName: this.accountForm.controls['lastName'].value,
        password: this.accountForm.controls['password'].value,
        email: this.accountForm.controls['email'].value
    }
  }

  onSubmit() {
  }

}
