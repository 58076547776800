import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { escapeSelector } from 'jquery';
import * as moment from 'moment';
import { ToastOptions, ToastyConfig, ToastyService } from 'ng2-toasty';
import { Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProductsService } from 'src/app/shared/service/products.service';
import { CourseModel } from '../../product.models';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {
  public productForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public onEdit: boolean;
  public productId: number;
  public id: number;
  public categories: any;
  public subcategories: any;

  public requiredFileType: string;
  public ImgfileName = '';
  public responseImgFile: string = '';
  public previewImg: any;
  public viewLoaderImg = false;

  public viewLoaderFile = false;
  public previewFile: any;
  public responseFile: string = '';
  public fileName = '';

  public ImgFileUrl: any;

  public counter: number = 1;
  public url = [{
    img: "assets/images/photo.png",
  }
  ]
  private subs: Array<Subscription> = [];
  dropdownSettings = {};
  subcategoryList = [];
  subcategoriesList = [];
  subcategoriesresult: any;
  ruta: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private productService: ProductsService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
  ) {

    this.ruta = this.router.url.split("/").pop();
    this.toastyConfig.theme = 'bootstrap';
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }

  onImgSelected(event) {
    const file: File = event.target.files[0];

    if (file.type == 'image/png' || file.type == 'image/jpeg') {
      this.viewLoaderImg = true;
      setTimeout(() => {
        if (file) {
          const formData = new FormData();
          formData.append('profileImg', file);

          this.subs.push(
            this.productService.uploadImg(formData)
              .subscribe(resolve => {
                if (resolve.FileUrl !== null) {
                  this.responseImgFile = resolve.ImageUrl;
                  this.ImgFileUrl = '';
                  this.viewLoaderImg = false;
                  this.viewBase64(file).then(image => {
                    this.previewImg = image;
                  })
                  this.ImgfileName = file.name;
                }
              })
          );
        }
      }, 1800);
    } else {
      this.NoTypeImg()
    }
  }

  viewBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };
    } catch (e) {
      return null;
    }
  })

  onFileSelected(event) {
    const file: File = event.target.files[0];

    if (file.type == 'application/pdf') {
      this.viewLoaderFile = true;
      this.fileName = 'Cargando archivo...';
      setTimeout(() => {
        if (file) {
          const formData = new FormData();
          formData.append('archiveupload', file);

          this.subs.push(
            this.productService.uploadFileWithoutID(formData)
              .subscribe(resolve => {
                if (resolve.ImageUrl !== null) {

                  this.responseFile = resolve.ImageUrl;
                  this.viewLoaderFile = false;
                  this.previewFile = "assets/images/check.png";
                  this.fileName = 'Archivo cargado correctamente';
                }
              })
          );
        }
      }, 1800);
    } else {
      this.NoTypeFile()
    }
  }


  readUrl(event: any, i) {
    if (event.target.files.length === 0)
      return;

    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url[i].img = reader.result.toString();
    }
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'ValueAlpha',

      unSelectAllText: 'Quitar todos',
      enableCheckAll: false,
      allowSearchFilter: true,
      searchPlaceholderText: 'Buscar',
      noDataAvailablePlaceholderText: 'Buscando datos'
    };
    this.subs.push(
      this.activatedRoute.paramMap.subscribe((data: any) => {
        if (data.params.id) {
          this.productId = data.params.id;
          this.onEdit = true;
          this.productService.getProduct(data.params.id)
            .subscribe(product => this.setValues(product));
          this.productForm = this.fb.group({
            AccessType: ['', [Validators.required]],
            Category: ['', [Validators.required]],
            CourseModel: ['', [Validators.required]],
            CurrencyCode: ['', [Validators.required]],
            Description: ['', [Validators.required]],
            Introduction: ['', Validators.required],
            Language: ['', Validators.required],
            LanguageSubtitles: ['', Validators.required],
            Level: ['', Validators.required],
            Objective: ['', Validators.required],
            SKU: ['', Validators.required],
            SubCategory: [''],
            TargetMarket: ['', Validators.required],
            Title: ['', Validators.required],
            TotalHours: ['', [Validators.required, Validators.min(0)]],
            UnitPrice: ['', [Validators.required, Validators.min(0)]],
            DiscountPrice: [''],

          })
        } else {
          this.onEdit = false;
          this.productForm = this.fb.group({
            AccessType: ['', [Validators.required]],
            Category: ['', [Validators.required]],
            CourseModel: ['', [Validators.required]],
            CurrencyCode: ['', [Validators.required]],
            Description: ['', [Validators.required]],
            Introduction: ['', Validators.required],
            Language: ['', Validators.required],
            LanguageSubtitles: ['', Validators.required],
            Level: ['', Validators.required],
            Objective: ['', Validators.required],
            SKU: ['', Validators.required],
            SubCategory: [''],
            TargetMarket: ['', Validators.required],
            Title: ['', Validators.required],
            TotalHours: ['', [Validators.required, Validators.min(0)]],
            UnitPrice: ['', [Validators.required, Validators.min(0)]],
            DiscountPrice: [''],

          })
        }
        this.productService
          .getCategories()
          .subscribe(data => {
            this.categories = data;
          })
        this.productService
          .getSubCategories()
          .subscribe(data => {
            this.subcategoryList = data;
          })
      })
    );
  }

  selectSubCategory(item: any) {
    this.subcategoriesList.push(item.ValueAlpha);
    const subList = [...this.subcategoriesList];
    this.subcategoriesresult = subList;
  }
  deselectSubCategory(item: any) {
    this.subcategoriesList = this.subcategoriesList.filter(subcategory => subcategory !== item.ValueAlpha);
    const subList = [...this.subcategoriesList];
    this.subcategoriesresult = subList;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public get formState() {
    return this.productForm.controls
  }

  private setValues(product: any): void {
    
    const values = this.productService.mapRequiredValues(product);
    this.id = values.id;

    this.ImgFileUrl = values.ImgFileUrl;
    this.previewFile = values.FileUrl;
    const { controls } = this.productForm;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.productForm.controls[value].setValue(values[value]);
      }
    }

    if(product.SubCategory.indexOf('[') !== -1){
      this.productForm.controls['SubCategory'].setValue(JSON.parse(product.SubCategory));
    }

    this.responseImgFile = product.ImgFileUrl;
    this.responseFile = product.FileUrl;
    this.fileName = product.FileUrl ? "Archivo Cargado" : '';
  }

  private buildCourse(): CourseModel {
    let index = this.router.url.split('/').indexOf("courses");
    return {
      AccessType: this.productForm.controls['AccessType'].value,
      Category: this.productForm.controls['Category'].value,
      CourseModel: this.productForm.controls['CourseModel'].value,
      CurrencyCode: this.productForm.controls['CurrencyCode'].value,
      Description: this.productForm.controls['Description'].value,
      Family: index != -1 ? 'Course' : 'Capacitation',
      Introduction: this.productForm.controls['Introduction'].value,
      Language: this.productForm.controls['Language'].value,
      LanguageSubtitles: this.productForm.controls['LanguageSubtitles'].value,
      Level: this.productForm.controls['Level'].value,
      Objective: this.productForm.controls['Objective'].value,
      SKU: this.productForm.controls['SKU'].value,
      SubCategory: JSON.stringify(this.subcategoriesresult),
      TargetMarket: this.productForm.controls['TargetMarket'].value,
      Title: this.productForm.controls['Title'].value,
      TotalHours: this.productForm.controls['TotalHours'].value,
      UnitMeasureCode: 'Curso',
      UnitPrice: this.productForm.controls['UnitPrice'].value,
      DiscountPrice: this.productForm.controls['DiscountPrice'].value,
      ImgFileUrl: this.responseImgFile,
      FileUrl: this.responseFile,
      Active: false

    }
  }

  private buildCourseUpdated(): CourseModel {
    let index = this.router.url.split('/').indexOf("courses");
    return {
      id: this.id,
      AccessType: this.productForm.controls['AccessType'].value,
      Category: this.productForm.controls['Category'].value,
      CourseModel: this.productForm.controls['CourseModel'].value,
      CurrencyCode: this.productForm.controls['CurrencyCode'].value,
      Description: this.productForm.controls['Description'].value,
      Family: index != -1 ? 'Course' : 'Capacitation',
      Introduction: this.productForm.controls['Introduction'].value,
      Language: this.productForm.controls['Language'].value,
      LanguageSubtitles: this.productForm.controls['LanguageSubtitles'].value,
      Level: this.productForm.controls['Level'].value,
      Objective: this.productForm.controls['Objective'].value,
      SKU: this.productForm.controls['SKU'].value,
      SubCategory: JSON.stringify(this.subcategoriesresult),
      TargetMarket: this.productForm.controls['TargetMarket'].value,
      Title: this.productForm.controls['Title'].value,
      TotalHours: this.productForm.controls['TotalHours'].value,
      UnitMeasureCode: 'Curso',
      UnitPrice: this.productForm.controls['UnitPrice'].value,
      DiscountPrice: this.productForm.controls['DiscountPrice'].value,
      ImgFileUrl: this.responseImgFile,
      FileUrl: this.responseFile

    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.productForm.invalid) {
      return;
    }

    if (this.responseFile === ''){
      this.NoFile();
      return;
    }

    if(this.responseImgFile === ''){
      this.NoImg();
      return;
    }

    if (this.onEdit) {
      this.updateCourse();
    } else {
      this.createCourse();
    }
  }

  createCourse() {
    const course: CourseModel = this.buildCourse();
    this.isLoading = true;

    this.subs.push(
        this.productService.createCourse(course)
        .pipe(
        map(() => {
            this.handleCourseCreation();
            setTimeout(() => {
              if( this.ruta.indexOf("add-") != 0 ){
                if( this.ruta == 'add-course' )
                  this.router.navigateByUrl('products/courses');
                else
                  this.router.navigateByUrl('products/capacitations');
              }else{
                if( this.router.url.indexOf("courses") != -1 )
                  this.router.navigateByUrl('products/courses');
                else
                  this.router.navigateByUrl('products/capacitations');
              }
            }, 1800);
        }),
        catchError(error => this.handleError(error))
        )
        .subscribe()
    );
  }

  updateCourse() {
    const course: CourseModel = this.buildCourseUpdated();

    this.isLoading = true;


    this.subs.push(
        this.productService.updateCourse(this.productId, course)
        .pipe(
        map(() => {
            this.handleCourseEdition();
            setTimeout(() => {
              if(this.router.url.indexOf("courses") !== -1){
                this.router.navigateByUrl('products/courses');
              }else if(this.router.url.indexOf("capacitations") !== -1){
                this.router.navigateByUrl('products/capacitations');
              }else if(this.router.url.indexOf("webinars") !== -1){
                this.router.navigateByUrl('products/webinars');
              }else if(this.router.url.indexOf("events") !== -1){
                this.router.navigateByUrl('products/events');
              }
            }, 1800);
        }),
        catchError(error => this.handleUpdateError(error))
        )
        .subscribe()
    );
  }

  private handleCourseCreation() {
    const toastOptions: ToastOptions = {
      title: 'Producto',
      msg: 'Producto creado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private handleCourseEdition() {
    const toastOptions: ToastOptions = {
      title: 'Producto',
      msg: 'Producto actualizado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private handleError(error: any) {
    if(error?.error.errors[0].message === "SKU must be unique" ){
      const toastOptions: ToastOptions = {
        title: 'Error',
        msg: 'SKU repetido',
        showClose: true,
        timeout: 2000
      };
      this.isLoading = false;
      this.toastyService.error(toastOptions);

    }else{
      const toastOptions: ToastOptions = {
        title: 'Error',
        msg: 'No se pudo crear el producto',
        showClose: true,
        timeout: 2000
      };
      this.isLoading = false;
      this.toastyService.error(toastOptions);
    }

    return [];
  }

  private handleUpdateError(error: any) {

    if(error?.error.errors[0].message === "SKU must be unique" ){
      const toastOptions: ToastOptions = {
        title: 'Error',
        msg: 'SKU repetido',
        showClose: true,
        timeout: 2000
      };
      this.isLoading = false;
      this.toastyService.error(toastOptions);

    }else{
      const toastOptions: ToastOptions = {
        title: 'Error',
        msg: 'No se pudo editar el producto',
        showClose: true,
        timeout: 2000
      };
      this.isLoading = false;
      this.toastyService.error(toastOptions);
    }

    
    

    return [];
  }

  private NoTypeImg() {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes subir una imagen de tipo png o jpg',
      showClose: true,
      timeout: 1800
    };

    this.toastyService.error(toastOptions);
  }

  private NoTypeFile() {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes subir un archivo de tipo pdf',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.error(toastOptions);
  }

  private NoImg() {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes cargar una imagen de portada',
      showClose: true,
      timeout: 1800
    };

    this.toastyService.error(toastOptions);
  }

  private NoFile() {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes cargar un manual',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.error(toastOptions);
  }

}
