import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CKEditorModule } from 'ngx-ckeditor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NewsRoutingModule } from './news-routing.module';

import { ToastyModule } from 'ng2-toasty';
import 'hammerjs';
import 'mousetrap';

import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablesModule } from 'angular-datatables';
import { NewsComponent } from './news-list/news-list.component';
import { AddNewsComponent } from './add-news/add-news.component';
import { NewsDetailComponent } from './news-detail/news-detail.component';
import { AngularEditorModule } from '@kolkov/angular-editor';


@NgModule({
  declarations: [
    NewsComponent,
    AddNewsComponent,
    NewsDetailComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    NewsRoutingModule,
    NgbModule,
    ToastyModule,
    NgSelectModule,
    DataTablesModule,
    AngularEditorModule
  ],
  providers: [,
    NgbActiveModal
  ]
})
export class NewsModule { }
