import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationType } from '../../interfaces/notification.enums';
import { UserModel } from '../../auth/auth.models';
import { UsersService } from '../../services/users/users.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public formpassword: FormGroup;
  public submitted = false;
  public isLoading = false;
  public isLoadingPass = false;
  public submittedProfile = false;
  public onEdit = false;
  public userId: number;
  public disabled = true;
  public countOrders: number;
  public countCourses: number;

  private subs: Array<Subscription> = [];
  public user: any;

  constructor(
    private fb: FormBuilder,
    private userService: UsersService,
    private authService: AuthenticationService,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.toastyConfig.theme = 'bootstrap';
  }

  ngOnInit(): void {
    this.subs.push(
      this.activatedRoute.paramMap.subscribe((data: any) => {
        if (data.params.id) {
          this.userId = data.params.id;
          this.onEdit = true;
          this.authService.getUser(data.params.id)
            .subscribe(user => {
              this.setValues(user);
              this.setValuesPassword(user)
              this.user = user;

              this.countOrders = 7
              this.countCourses = 12;
            })
        }
      })
    );

    this.buildUser();
    this.buildPassword();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  private validatePasswords(): void {
    const password: string = this.formpassword.get('password').value;
    const confirmPassword: string = this.formpassword.get('confirmedPassword').value;

    if (password !== confirmPassword) {
      this.formpassword.controls['confirmedPassword'].setErrors({ incorrect: true });
    };
  }

  public get formState(): any {
    return this.form.controls;
  }

  public get formStatePass(): any {
    return this.formpassword.controls;
  }

  public buildUser(): void {
    this.form = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required, Validators.pattern("[0-9]{10}")]],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      city: ['', [Validators.required]],
      country: ['', [Validators.required]],
      birthday: ['', [Validators.required]],
    })
  }

  private buildUserUpdate(): UserModel {
    return {
      firstName: this.form.controls['firstName'].value,
      lastName: this.form.controls['lastName'].value,
      email: this.form.controls['email'].value,
      phoneNumber: this.form.controls['phoneNumber'].value,
      city: this.form.controls['city'].value,
      country: this.form.controls['country'].value,
      birthday: this.form.controls['birthday'].value,
    }
  }

  public buildPassword(): void {
    this.formpassword = this.fb.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmedPassword: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  updateUser() {
    this.submittedProfile = true;
    if (this.form.invalid) {
      return;
    }
    const user: UserModel = this.buildUserUpdate();
    this.isLoading = true;
    this.subs.push(
      this.userService.updateUser(this.userId, user)
        .pipe(
          map(() => {
            this.handleUserEdit();
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }),
          catchError(error => this.handleError(error))
        )
        .subscribe()
    );
  }

  public onChangePassword(): void {
    this.submitted = true;
    this.validatePasswords();

    if (this.formpassword.invalid) {
      return;
    }

    const password: any = this.buildPassword();
    this.isLoadingPass = true;
    this.subs.push(
      this.authService.changePassword(password)
      .pipe(catchError(err => {
        this.sendNotification('Error al cambiar la contraseña', err.error?.message, NotificationType.error);
        return throwError(err);
      }))
      .subscribe(() => this.sendNotification(null, 'Contraseña actualizada correctamente', NotificationType.success))
    );
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  }

  private setValues(user: any): void {
    const values = this.authService.mapRequiredValues(user);
    const { controls } = this.form;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.form.controls[value].setValue(values[value]);
      }
    }
  }

  private setValuesPassword(userpass: any): void {
    const values = this.authService.mapRequiredValuesForPassword(userpass);
    const { controls } = this.formpassword;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.formpassword.controls[value].setValue(values[value]);
      }
    }
  }

  private sendNotification(title: string = 'Hub de seguridad', message: string, type: string): void {
    const toastOptions: ToastOptions = {
      title,
      msg: message,
      showClose: true,
      timeout: 3500,
      theme: 'bootstrap'
    };

    this.toastyService[type](toastOptions);
  }

  private handleUserEdit() {
    const toastOptions: ToastOptions = {
      title: 'Perfil',
      msg: 'Usuario actualizado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private handleError(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudo actualizar el usuario',
      showClose: true,
      timeout: 2000
    };
    this.isLoading = false;
    this.toastyService.error(toastOptions);

    return [];
  }

}
