<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row mb-20">
    <div class="col-xs-12 col-sm-6">
    </div>
    <div class="col-xs-12 col-sm-6 text-right">
        <div class="action">
            <a class="btn btn-red btn-sm"
              [routerLink]="['create-user']">
              <i class="ti-plus"></i>
              <span>Nuevo Administrador</span>
            </a>
        </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
        <label>Filtrar</label>
        <div class="input-group mb-3">
          <input type="text" id="filterText" placeholder="Buscar por nombre, correo o teléfono" class="form-control">
          <div class="input-group-append">
            <button *ngIf="!deleteFilters" value="Buscar" (click)="filter()" class="btn btn-primary">Buscar</button>
            <button *ngIf="deleteFilters" value="Borrar" (click)="getUsers()" class="btn btn-primary">Borrar filtros</button>
          </div>
        </div>
    </div>
  </div>

  <div class="card">
      <div class="card-body">
          <div id="batchDelete" class="">
              <div class="table-responsive">
                  <table datatable
                  [dtOptions]="dtOptions"
                  [dtTrigger]="dtTrigger"
                  class=" table table-lg table-hover row-border hover">
                  <thead>
                      <tr>
                      <th>Nombre(s)</th>
                      <th>Correo electrónico</th>
                      <th>Teléfono</th>
                      <th>Status</th>
                      <th>Acciones</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let user of user_list">
                      <td>{{user.firstName}} {{user.lastName}}</td>
                      <td>{{user.email}}</td>
                      <td>{{user.phoneNumber}}</td>
                      <td>{{user.active ? 'activo': 'inactivo'}}</td>
                      <td class="actions-cell">
                          <span>
                          <a [routerLink]="['show', user.id]"
                              title="Ver administrador">
                              <i class="fa fa-eye"></i>
                          </a>
                          </span>
                          <span>
                            <a [routerLink]="['edit', user.id]"
                                title="Editar administrador">
                                <i class="fa fa-edit"></i>
                            </a>
                          </span>
                          <span (click)="setModalContent(content, user.id)"*ngIf="user.active"
                          title="Borrar administrador">
                          <i class="fa fa-trash"></i>
                          </span>

                          <span (click)="setModalContent(contentactive, user.id)" *ngIf="!user.active"
                            title="Activar administrador">
                            <i class="fa fa-check"></i>
                            </span>

                      </td>
                      </tr>
                  </tbody>
                  </table>
                  <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="count" [(page)]="page" [maxSize]="10" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
              </div>
          </div>
      </div>
  </div>
</div>

<ng-template #content
  let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100"
      id="modal-title">Eliminar Administrador</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">Se eliminirán todos los datos del administrador.</p>
      <p class="no-mrg">Esta acción no se puede deshacer.</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>Administrador borrado correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a>
      <button type="button"
        class="btn btn-inverse btn-danger"
        (click)="deleteUser()">Eliminar</button>
    </div>
  </ng-container>
</ng-template>

<ng-template #contentactive
  let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100"
      id="modal-title">Activar Administrador</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">Activara a este administrador dentro de la plataforma</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>Administrador activado correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a>
      <button type="button"
        class="btn btn-inverse btn-success"
        (click)="activeUser()">Activar</button>
    </div>
  </ng-container>
</ng-template>