import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { EmailModel } from '../auth.models';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy{

  public forgotPasswordForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public submittedEmail = false;

  private subs: Array<Subscription> = [];

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthenticationService,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig
  ) {
    this.toastyConfig.theme = 'bootstrap';
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
    })
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public get formState() {
    return this.forgotPasswordForm.controls
  }

  private emailUser(): EmailModel {
    return {
        email: this.forgotPasswordForm.controls['email'].value
    }
  }

  public onSubmit(): void {
    this.submittedEmail = true;

    if (this.forgotPasswordForm.invalid) {
      return;
    }

    const email: EmailModel = this.emailUser();
    this.isLoading = true;
    this.subs.push(
      this.authService.forgotPassword(email)
        .subscribe(resolve => {
            this.handleEmailSend();
            setTimeout(() => {
                this.router.navigateByUrl('/auth/login');
            }, 1800);
        }, error =>{
            this.handleError(error)
        })
    );
  }

  private handleEmailSend() {
    this.isLoading = false;
    const toastOptions: ToastOptions = {
      title: 'Hub de seguridad',
      msg: 'Correo enviado exitosamente',
      showClose: true,
      timeout: 1800
    };

    this.toastyService.success(toastOptions);
  }

  private handleError(error: any) {
    this.isLoading = false;
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No hemos podido enviar el correo electrónico',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);

    return [];
}

}
