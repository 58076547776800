import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CKEditorModule } from 'ngx-ckeditor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ProductsRoutingModule } from './products-routing.module';
import { CategoryComponent } from './physical/category/category.component';
import { SubCategoryComponent } from './physical/sub-category/sub-category.component';
import { ProductListComponent } from './physical/product-list/product-list.component';
import { AddProductComponent } from './physical/add-product/add-product.component';
import { DigitalCategoryComponent } from './digital/digital-category/digital-category.component';
import { DigitalSubCategoryComponent } from './digital/digital-sub-category/digital-sub-category.component';
import { DigitalListComponent } from './digital/digital-list/digital-list.component';
import { DigitalAddComponent } from './digital/digital-add/digital-add.component';
import { ProductDetailComponent } from './physical/product-detail/product-detail.component';
import { EventListComponent } from './event/event-list/event-list.component';
import { AddEventComponent } from './event/event-add/add-event.component';
import { AddQuizComponent } from '../quizs/add-quiz/add-quiz.component';
import { AddProgramComponent } from '../programs/add-programs/add-program.component';

import { ToastyModule } from 'ng2-toasty';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import 'hammerjs';
import 'mousetrap';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProgramsCourseComponent } from '../programs/programs-course/programs-course.component';
import { DataTablesModule } from 'angular-datatables';
import { FeaturesComponent } from './physical/features/features.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  maxFilesize: 50,
  url: 'https://httpbin.org/post',
};

@NgModule({
  declarations: [CategoryComponent,
    SubCategoryComponent,
    ProductListComponent,
    AddProductComponent,
    DigitalCategoryComponent,
    DigitalSubCategoryComponent,
    DigitalListComponent,
    DigitalAddComponent,
    ProductDetailComponent,
    EventListComponent,
    AddEventComponent,
    AddQuizComponent,
    AddProgramComponent,
    ProgramsCourseComponent,
    FeaturesComponent],
    
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    ProductsRoutingModule,
    Ng2SmartTableModule,
    NgbModule,
    ToastyModule,
    DropzoneModule,
    NgSelectModule,
    DataTablesModule,
    GalleryModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    NgbActiveModal
  ]
})
export class ProductsModule { }
