import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	rol?: string;
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}


	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/dashboard', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false, rol: 'all'
		},
		{
			title: 'Productos', icon: 'shield-off', type: 'sub', active: false, rol:'all', children: [
				{ path: '/products/courses', title: 'Cursos', type: 'link' },
				{ path: '/products/capacitations', title: 'Capacitaciones', type: 'link' },
				{ path: '/products/webinars', title: 'Webinars', type: 'link' },
				{ path: '/products/events', title: 'Eventos', type: 'link' },
				{ path: '/products/catalogs', title: 'Catálogos', type: 'link' }
			]
		},
		{
			title: 'Cuestionarios', path: '/quizs', icon: 'list', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Evaluaciones', path: '/evaluations', icon: 'check-circle', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Noticias', path: '/news', icon: 'book-open', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Wiki', path: '/wiki', icon: 'book', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Ordenes', path: '/sales/orders', icon: 'shopping-cart', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Pagos', path: '/sales/transactions', icon: 'dollar-sign', type: 'link', active: false
		},
		{
			title: 'Usuarios', icon: 'users', type: 'sub', active: false, children: [
				// { path: '/users/business', title: 'Empresas', type: 'link' },
				{ path: '/users', title: 'Miembros', type: 'link' },
				// { path: '/users/teachers', title: 'Instructores', type: 'link' },
				// { path: '/users/admins', title: 'Administradores', type: 'link' },
			]
		},
		{
			title: 'Carrera', icon: 'list', type: 'sub', active: false, rol: 'teacher', children: [
				{ path: '/courses', title: 'Mis cursos', type:'link' },
				{ path: '/students', title: 'Mis estudiantes', type:'link' }
			]
		},
		]

	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
