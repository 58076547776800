<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details text-center">
                        <img src="assets/images/dashboard/profile-user.png" alt=""
                            class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                        <h5 class="f-w-600 mb-0">{{user?.firstName}} {{user?.lastName}}</h5>
                        <span>{{user?.email}}</span>
                        <br>
                        <span>{{user?.phoneNumber}}</span>
                        <hr>
                        <span *ngIf="user?.roles == 'admin'">Perfil: Administrador</span>
                        <span *ngIf="user?.roles == 'teacher'">Perfil: Maestro</span>
                        <span *ngIf="user?.roles == 'student'">Perfil: Usuario</span>
                        <hr>
                        <!-- <span>Ciudad: {{user?.city}}</span><br> -->
                        <span>País: {{user?.country ? user?.country : 'Sin país'}}</span><br>
                        <span>Fecha de nacimiento: {{user?.birthday | date:'dd/MM/yyyy'}}</span>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
        <hr *ngIf="user?.roles == 'student'">
        <div class="col-xl-12" *ngIf="user?.roles == 'student'">
            <p>Pedidos:</p>
            <div class="card tab2-card">
                <div class="card-body">
                    <div id="batchDelete" class="">
                        <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                class=" table table-lg table-hover row-border hover">
                                <thead>
                                    <tr>
                                        <th>Pedido</th>
                                        <th>Fecha</th>
                                        <th>Total</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let order of course?.SaleOrder">
                                        <td>{{order.id}}</td>
                                        <td>{{order.SaleOrderDate | date:'dd/MM/yyyy'}}</td>
                                        <td>{{order.TotalAmount | currency}} {{order.CurrencyCode}}</td>
                                        <td>{{order.SaleOrderStatus}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr *ngIf="user?.roles == 'student'">
        <div class="col-xl-12" *ngIf="user?.roles == 'student'">
            <p>Cursos:</p>
            <div class="row products-admin ratio_asos">
                <div class="col-xl-4 col-sm-6 col-md-6" *ngFor="let course of course?.Courses">
                    <br>
                    <div class="card">
                        <div class="card-body product-box">
                            <div class="product-detail">
                                <div>
                                    <h6 *ngIf="course.Title.length > 32">{{course.Title.slice(0,32).concat('...')}}</h6>
                                    <h6 *ngIf="course.Title.length < 32">{{course.Title}}</h6>
                                </div>
                                <br>
                                <h4>{{course.TotalAmount | currency}}</h4>
                                <br>
                                <h5>{{course.SKU ? course.SKU : 'ㅤ'}}</h5>
                                <br>
                                <p>
                                    <ngb-progressbar [showValue]="true" type="danger" [value]="course.Percentage">
                                    </ngb-progressbar>
                                    <span *ngIf="course.Percentage === 0"
                                        style="color:black; position: absolute; top: 80%;right: 50%;">0%</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->